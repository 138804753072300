import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const platformId = inject(PLATFORM_ID);
  const router = inject(Router);
  if (!isPlatformBrowser(platformId)) {
    return false;
  }
  const authService = inject(AuthService);
  const role = route.data['role'] as string;

  if (!role) {
    throw new Error('RoleGuard: Role not provided in route data');
  }

  if (!authService.user) {
    return false;
  }

  const canAccess = authService.user.role.type === role;
  if (!canAccess) {
    return router.createUrlTree(['/not-authorized']);
  }

  return canAccess;
};

@if (isLoading()) {
  <div class="flex justify-center">
    <casa-doc-loader></casa-doc-loader>
  </div>
} @else {
  <casa-doc-input-area-file
    class="grid grow"
    [multiple]="true"
    accept=".jpg, .jpeg"
    [formControl]="fileControl"
  ></casa-doc-input-area-file>
  @if (warningMessage()) {
    <casa-doc-alert
      class="mt-4"
      type="danger"
      title="Attenzione"
      [message]="warningMessage()"
    ></casa-doc-alert>
  } @else {
    <div class="flex justify-center">
      <button
        class="mt-4 w-full md:w-auto btn-solid-blue-casa-doc"
        [disabled]="!fileControl.value.length"
        (click)="onWatermark()"
      >
        Applica Watermark
      </button>
    </div>
  }
}

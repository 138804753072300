import { EWatermarkPosition } from '../types/watermark';

interface CalculateCoordinates {
  position: EWatermarkPosition;
  imageDimensions: {
    height: number;
    width: number;
  };
  watermarkDimensions: {
    height: number;
    width: number;
  };
  watermarkIsText: boolean;
  offset?: number;
  offsetScale?: number;
}

export function calculateCoordinates({
  position,
  imageDimensions,
  watermarkDimensions,
  watermarkIsText,
  offset = 0,
  offsetScale = 0,
}: CalculateCoordinates) {
  const height = imageDimensions.height - watermarkDimensions.height;
  const width = imageDimensions.width - watermarkDimensions.width;
  const centerVertically = height / 2;
  const centerHorizontally = width / 2;

  const verticalStartOrigin = watermarkIsText
    ? watermarkDimensions.height * 3 + 10
    : 0;

  const verticalEndOrigin = watermarkIsText
    ? height - watermarkDimensions.height * 2
    : height;

  const offsetX = offsetScale ? imageDimensions.width * offsetScale : offset;
  const offsetY = offsetScale ? imageDimensions.height * offsetScale : offset;

  const positions: Record<EWatermarkPosition, { x: number; y: number }> = {
    [EWatermarkPosition.TOP_LEFT]: {
      x: 0 + offsetX,
      y: verticalStartOrigin + offsetY,
    },
    [EWatermarkPosition.TOP_CENTER]: {
      x: centerHorizontally,
      y: verticalStartOrigin + offsetY,
    },
    [EWatermarkPosition.TOP_RIGHT]: {
      x: width - offsetX,
      y: verticalStartOrigin + offsetY,
    },

    [EWatermarkPosition.CENTER]: { x: centerHorizontally, y: centerVertically },
    [EWatermarkPosition.CENTER_LEFT]: { x: 0 + offsetX, y: centerVertically },
    [EWatermarkPosition.CENTER_RIGHT]: {
      x: width - offsetX,
      y: centerVertically,
    },

    [EWatermarkPosition.BOTTOM_CENTER]: {
      x: centerHorizontally,
      y: verticalEndOrigin - offsetY,
    },
    [EWatermarkPosition.BOTTOM_LEFT]: {
      x: 0 + offsetX,
      y: verticalEndOrigin - offsetY,
    },
    [EWatermarkPosition.BOTTOM_RIGHT]: {
      x: width - offsetX,
      y: verticalEndOrigin - offsetY,
    },
  };

  return positions[position];
}

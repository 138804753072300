import {
  AsyncPipe,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import { Component, inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from 'app/core/services/auth/auth.service';

@Component({
  selector: 'casa-doc-header',
  standalone: true,
  imports: [NgOptimizedImage, AsyncPipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  authService = inject(AuthService);
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  onSignOut() {
    this.authService.signOut();
  }
}

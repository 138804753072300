import { WatermarkTextStyle } from '../types/watermark';

export class WatermarkCanvas {
  public readonly canvas: HTMLCanvasElement;
  public readonly context: CanvasRenderingContext2D;
  #quality = 1;

  private constructor() {
    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d')!;
  }

  public static create(props?: { width: number; height: number }) {
    const watermarkCanvas = new WatermarkCanvas();
    watermarkCanvas.canvas.width = props?.width || 0;
    watermarkCanvas.canvas.height = props?.height || 0;
    return watermarkCanvas;
  }

  public drawOriginal(
    // eslint-disable-next-line no-undef
    image: CanvasImageSource,
    coordX: number,
    coordY: number,
  ) {
    this.context.drawImage(
      image,
      coordX,
      coordY,
      this.canvas.width,
      this.canvas.height,
    );

    return this;
  }

  public drawWatermarkImage(
    // eslint-disable-next-line no-undef
    image: CanvasImageSource,
    coordX: number,
    coordY: number,
    width: number,
    height: number,
  ) {
    this.context.drawImage(image, coordX, coordY, width, height);

    return this;
  }

  public applyTextStyles(textStyle: WatermarkTextStyle) {
    textStyle = {
      weight: textStyle?.weight || 'bold',
      family: textStyle?.family || 'serif',
      size: textStyle?.size || '24px',
      color: textStyle?.color || '#000000',
      baseline: textStyle?.baseline || 'middle',
      scaleSize: textStyle?.scaleSize,
    };
    let size = textStyle.size;

    if (textStyle.scaleSize) {
      const watermarkWidth = this.canvas.width * textStyle.scaleSize;
      const watermarkHeight = this.canvas.height * textStyle.scaleSize;
      size = `${Math.floor(Math.min(watermarkWidth, watermarkHeight) * textStyle.scaleSize)}px`;
    }

    this.context.fillStyle = textStyle.color!;
    this.context.textBaseline = textStyle.baseline!;
    this.context.font = `${textStyle.weight} ${size} ${textStyle.family}`;

    return this;
  }

  public drawWatermarkText(text: string, coordX: number, coordY: number) {
    this.context.fillText(text, coordX, coordY);

    return this;
  }

  public result(resolve: (value: Blob | PromiseLike<Blob>) => void) {
    this.context.canvas.toBlob(
      (_blob) => {
        resolve(_blob!);
      },
      'image/jpeg',
      this.#quality,
    );
  }

  enhance({
    brightnessFactor = 1,
    contrastFactor = 1,
    saturationFactor = 1,
  }: {
    brightnessFactor: number;
    contrastFactor: number;
    saturationFactor: number;
  }) {
    const imageData = this.context.getImageData(
      0,
      0,
      this.canvas.width,
      this.canvas.height,
    );
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] = this.enhanceBrightness(data[i], brightnessFactor); // R
      data[i + 1] = this.enhanceBrightness(data[i + 1], brightnessFactor); // G
      data[i + 2] = this.enhanceBrightness(data[i + 2], brightnessFactor); // B

      data[i] = this.enhanceContrast(data[i], contrastFactor); // R
      data[i + 1] = this.enhanceContrast(data[i + 1], contrastFactor); // G
      data[i + 2] = this.enhanceContrast(data[i + 2], contrastFactor); // B

      const hsv = this.rgbToHsv(data[i], data[i + 1], data[i + 2]);
      hsv[1] *= saturationFactor;
      const rgb = this.hsvToRgb(hsv[0], hsv[1], hsv[2]);

      data[i] = rgb[0];
      data[i + 1] = rgb[1];
      data[i + 2] = rgb[2];
    }

    // Riporta l'immagine migliorata nel canvas
    this.context.putImageData(imageData, 0, 0);
    return this;
  }

  private enhanceBrightness(value: number, factor: number): number {
    return Math.min(255, Math.max(0, value * factor));
  }

  private enhanceContrast(value: number, factor: number): number {
    return Math.min(255, Math.max(0, factor * (value - 128) + 128));
  }

  // Conversione da RGB a HSV
  private rgbToHsv(r: number, g: number, b: number): [number, number, number] {
    let v = Math.max(r, g, b),
      c = v - Math.min(r, g, b);
    let h =
      c && (v == r ? (g - b) / c : v == g ? 2 + (b - r) / c : 4 + (r - g) / c);
    return [60 * (h < 0 ? h + 6 : h), v && c / v, v / 255];
  }

  // Conversione da HSV a RGB
  private hsvToRgb(h: number, s: number, v: number): [number, number, number] {
    const f = (n: number, k = (n + h / 60) % 6) =>
      v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
    return [f(5) * 255, f(3) * 255, f(1) * 255];
  }
}

export interface IJwtToken {
  token: string;
  refreshToken?: string;
  expiresAt: number;
  expiresIn: number;
  userId?: number | string;
  username?: string;
  id?: number;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  at_hash: string;
  nonce: string;
}

export interface IJwtLoginResponse {
  jwt: string;
  user?: IUser;
}

export enum EAuthState {
  TO_BE_LOGGED = 'TO_BE_LOGGED',
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  TOKEN_RETRIEVED = 'TOKEN_RETRIEVED',
  LOGGED = 'LOGGED',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_INFO = 'LOGIN_INFO',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  NOT_AUTHORISED = 'NOT_AUTHORISED',
}

export interface IUser {
  id?: number;
  documentId?: string;
  createdAt?: Date;
  updatedAt?: Date;

  firstName: string;
  lastName: string;
  email: string;
  blocked: boolean;
  confirmed: boolean;
  linkAgentId: string;
  locale: string | null;
  name: string;
  provider: string;
  publishedAt: string;
  surname: string;
  username: string;
  role: Role;
  agentAgency: AgentAgency;
}

export class Role {
  id?: number;
  documentId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  name: string;
  description: string;
  type: ERoleType;
  locale: string | null;
}

interface ImageFormat {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
  sizeInBytes: number;
}

interface CompanyLogo {
  id: number;
  documentId: string;
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number;
  height: number;
  formats: {
    large: ImageFormat;
    small: ImageFormat;
    medium: ImageFormat;
    thumbnail: ImageFormat;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
  locale: string | null;
}

interface Group {
  id: number;
  documentId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
  locale: string | null;
  companyLogoPosition: ELogoPosition;
  companyLogoSizePercentage: number;
  casaDocLogoPosition: ELogoPosition;
  companyLogo: CompanyLogo;
}

interface AgentAgency {
  id: number;
  documentId: string;
  name: string;
  address: string | null;
  vat: string | null;
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
  locale: string | null;
  group: Group;
}

export enum ELogoPosition {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  CENTER = 'CENTER',
}

export enum ERoleType {
  ADMIN = 'account',
  AGENT = 'agent',
  AUTHENTICATED = 'authenticated',
  PUBLIC = 'public',
  SECRETARY = 'secretary',
  SELLER = 'seller',
  SURVEYOR = 'surveyor',
}

import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'casa-doc-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  title = input<string>();
  message = input<string>();
  type = input<'info' | 'danger' | 'warning' | 'success'>();
}

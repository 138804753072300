<header class="border-b border-gray-200">
  <nav>
    <div
      class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between py-8"
    >
      <div class="relative z-10 flex items-center gap-16">
        <img src="images/logo_casadoc.svg" alt="Casa Doc" class="h-10 w-auto" />
      </div>

      <div class="relative z-10 flex items-center">
        @if (isBrowser && authService.user) {
          <button class="btn-outline-gray" (click)="onSignOut()">Logout</button>
        }
      </div>
    </div>
  </nav>
</header>

export interface Watermarked extends File {
  width: number;
  height: number;
}

export enum EWatermarkPosition {
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  TOP_RIGHT = 'TOP_RIGHT',
  CENTER = 'CENTER',
  CENTER_LEFT = 'CENTER_LEFT',
  CENTER_RIGHT = 'CENTER_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export interface WatermarkBaseCoordinates {
  x: number;
  y: number;
}

export type WatermarkCoordinates =
  | {
      landscape: WatermarkBaseCoordinates;
      portrait?: undefined;
      x?: undefined;
      y?: undefined;
    }
  | {
      portrait: WatermarkBaseCoordinates;
      landscape?: undefined;
      x?: undefined;
      y?: undefined;
    }
  | {
      landscape: WatermarkBaseCoordinates;
      portrait: WatermarkBaseCoordinates;
      x?: undefined;
      y?: undefined;
    }
  | { x: number; y: number; landscape?: undefined; portrait?: undefined };

export interface WatermarkImage {
  path: string;
  coordinates?: WatermarkCoordinates;
  position?: EWatermarkPosition;
  offset?: number;
  offsetScale?: number;
  scale?: number;
}

export interface WatermarkTextStyle {
  weight?: string | number;
  family?: string;
  // eslint-disable-next-line no-undef
  color?: CanvasFillStrokeStyles['fillStyle'];
  size?: string;
  scaleSize?: number;
  // eslint-disable-next-line no-undef
  baseline?: CanvasTextBaseline;
}

export interface WatermarkText {
  text: string;
  style?: WatermarkTextStyle;
  coordinates?: WatermarkCoordinates;
  position?: EWatermarkPosition;
  offset?: number;
  offsetScale?: number;
}

<div
  class="border-2 border-dashed border-gray-300 p-4 h-full w-full flex flex-col justify-center items-center text-center text-lg text-gray-600 bg-gray-100 transition-colors duration-300 ease-in-out rounded-3xl p-6 shadow-lg shadow-gray-900/5"
  [ngClass]="{ 'file-over': fileOver() }"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    class="hidden"
    [multiple]="multiple()"
    [accept]="accept()"
    (change)="fileBrowseHandler($any($event.target).files)"
  />

  <div class="flex flex-col justify-items-center w-60">
    <h3>Trascina e rilascia qui</h3>
    <h3>oppure</h3>

    <button class="mt-4 w-full btn-solid-gray" (click)="fileDropRef.click()">
      <span>Sfoglia i file</span>
    </button>
    <div
      class="flex gap-2 items-center mt-6 flex-col"
      [ngClass]="{ invisible: !files().length }"
    >
      <span>{{ files().length }} File caricati</span>

      <button class="btn-outline-gray" (click)="onClearAll()">Rimuovi</button>
    </div>
  </div>
</div>

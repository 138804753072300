import { CommonModule } from '@angular/common';
import { Component, effect, input, signal } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'casa-doc-input-area-file',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './input-area-file.component.html',
  styleUrl: './input-area-file.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputAreaFileComponent,
      multi: true,
    },
  ],
})
export class InputAreaFileComponent implements ControlValueAccessor {
  accept = input<string>('*');
  multiple = input<boolean>(false);
  // disabled = input<boolean>(false);
  files = signal<File[]>([]);
  fileOver = signal<boolean>(false);

  constructor() {
    effect(
      () => {
        this.writeValue(this.files());
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.set(true);
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.set(false);
  }

  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    let files: File[] = [];
    if (event.dataTransfer.items) {
      for (const item of Array.from(event.dataTransfer.items)) {
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      }
    } else {
      for (const item of Array.from(event.dataTransfer.files)) {
        files.push(item);
      }
    }

    const acceptedExtensions = this.accept()
      .split(',')
      .map((ext) => ext.trim().toLowerCase());
    files = files.filter((file) => {
      const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
      return (
        acceptedExtensions.includes(fileExtension) ||
        acceptedExtensions.includes('*')
      );
    });

    this.files.update((prev) => [...prev, ...files]);
    this.fileOver.set(false);
  }

  fileBrowseHandler(files: File[]) {
    for (const item of files) {
      this.files.update((prev) => [...prev, item]);
    }
  }

  onClearAll() {
    this.files.set([]);
  }

  writeValue(value: File[]): void {
    this.files.set(value);
    this.onChange(this.files());
  }
  onChange = (value: File[]) => {};

  onTouched = () => {};

  registerOnChange(fn: (value: File[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}

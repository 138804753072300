<div
  class="p-4 mb-4 text-sm rounded-lg shadow-lg border"
  [ngClass]="{
    'text-blue-800 bg-blue-50 border-blue-300': type() === 'info',
    'text-red-800 bg-red-50 border-red-300': type() === 'danger',
    'text-green-800 bg-green-50 border-green-300': type() === 'success',
    'text-yellow-800 bg-yellow-50 border-yellow-300': type() === 'warning',
  }"
>
  @if (title()) {
    <div class="font-bold">
      {{ title() }}
    </div>
  }
  @if (message()) {
    <div>
      {{ message() }}
    </div>
  }
</div>

<div class="flex items-center justify-center flex-col">
  <h1 class="text-center text-2xl font-medium tracking-tight text-gray-900">
    Accedi
  </h1>

  <div
    class="mt-10 flex-auto bg-white px-4 py-10 shadow-2xl shadow-gray-900/10 sm:mx-0 sm:flex-none sm:rounded-5xl sm:p-24"
  >
    <form [formGroup]="form" class="w-80 md:w-96">
      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-600 mb-1" for="email"
          >Email</label
        >
        <input
          id="email"
          type="email"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Inserisci la tua email"
          formControlName="identifier"
          required
        />
      </div>

      <div class="mb-6">
        <label
          class="block text-sm font-medium text-gray-600 mb-1"
          for="password"
          >Password</label
        >
        <input
          id="password"
          type="password"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Inserisci la tua password"
          formControlName="password"
          required
        />
      </div>
      <div class="flex items-center justify-center">
        @if (
          [eAuthState.LOGIN_IN_PROGRESS, eAuthState.TOKEN_RETRIEVED].includes(
            authService.authState$ | async
          )
        ) {
          <casa-doc-loader></casa-doc-loader>
        } @else {
          <button
            class="mt-4 w-full btn-solid-blue-casa-doc"
            type="submit"
            [disabled]="!form.valid"
            (click)="onLogin()"
          >
            Login
          </button>
        }
      </div>

      @if (authService.loginErrorMessage()) {
        <casa-doc-alert
          class="mt-4 block"
          type="danger"
          title="Errore"
          [message]="authService.loginErrorMessage()"
        ></casa-doc-alert>
      }
    </form>
  </div>
</div>

import { afterNextRender, Component, inject, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { InputAreaFileComponent } from 'app/shared/components/input-area-file/input-area-file.component';
import { promptToDownloadBlob } from 'app/utils/file.utils';
import JSZip from 'jszip';
import { Watermark } from 'libs/watermarkjs';
import { EWatermarkPosition } from 'libs/watermarkjs/types/watermark';
import { AlertComponent } from '../../shared/components/alert/alert.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
@Component({
  selector: 'casa-doc-watermark',
  standalone: true,
  imports: [
    InputAreaFileComponent,
    FormsModule,
    ReactiveFormsModule,
    AlertComponent,
    LoaderComponent,
  ],
  templateUrl: './watermark.component.html',
  styleUrl: './watermark.component.scss',
})
export class WatermarkComponent {
  #authService = inject(AuthService);

  fileControl = new FormControl<File[]>([], { nonNullable: true });
  isLoading = signal<boolean>(false);
  warningMessage = signal<string>(null);

  constructor() {
    afterNextRender(() => {
      const agencyGroup = this.#authService.user?.agentAgency?.group;
      if (!agencyGroup) {
        this.warningMessage.set(
          "La tua agenzia non ha un gruppo associato. Contatta l'amministratore per risolvere il problema.",
        );
      } else if (!agencyGroup.casaDocLogoPosition) {
        this.warningMessage.set(
          "Non è stata impostata la posizione del logo Casa-Doc per il watermark. Contatta l'amministratore per risolvere il problema.",
        );
      } else if (
        !agencyGroup?.companyLogo ||
        !agencyGroup?.companyLogoPosition ||
        !agencyGroup?.companyLogoSizePercentage
      ) {
        this.warningMessage.set(
          "Non è stato impostato correttamente il logo del gruppo. Contatta l'amministratore per risolvere il problema.",
        );
      }
    });
  }

  async onWatermark() {
    const agencyGroup = this.#authService.user?.agentAgency?.group;

    try {
      this.isLoading.set(true);
      const promises = this.fileControl.value.map(async (file) => {
        const watermark = await Watermark.load(file);
        if (agencyGroup?.casaDocLogoPosition) {
          await watermark.applyImage({
            path: 'images/casadoc_watermark_white_small.png',
            position: EWatermarkPosition[agencyGroup.casaDocLogoPosition],
            // offsetScale: 0.01, // add a 2% offset of the image width/height
            scale: 0.1, // make the watemark as 20% of the image width/height
            offset: 10
          });
        }

        if (agencyGroup?.companyLogo && agencyGroup?.companyLogoPosition) {
          await watermark.applyImage({
            path: agencyGroup.companyLogo.url,
            position: EWatermarkPosition[agencyGroup.companyLogoPosition],
            offsetScale: 0.02, // add a 2% offset of the image width/height
            scale: agencyGroup.companyLogoSizePercentage / 100,
          });
        }

        await watermark.enhanceImage({
          brightnessFactor: 1.1, // +10%
          contrastFactor: 1.2, // +20%
          saturationFactor: 1.2, // +20%
        });
        return watermark.getResult();
      });

      const files = await Promise.all(promises);
      const zip = new JSZip();
      files.forEach((file) => {
        zip.file(file.name, file, { base64: true });
      });
      const zipFile = await zip.generateAsync({ type: 'blob' });
      promptToDownloadBlob(zipFile, 'zip', 'casa-doc-watermark-' + Date.now());
      this.fileControl.reset([]);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading.set(false);
    }
  }
}

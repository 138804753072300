import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '../services/auth/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  if (
    req.url.includes(environment.apiUrl) &&
    !req.url.includes('auth') &&
    authService.authTokenMetaData?.token
  ) {
    const authReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${authService?.authTokenMetaData?.token}`,
      ),
    });
    return next(authReq);
  }
  return next(req);
};
